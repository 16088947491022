import React from 'react';
import FluidImage from '../../../common/FluidImage/FluidImage';
import Section from '../../../layout/section/section';
import style from './comic-pages-section.module.scss';

const ComicPagesSection = () => (
  <Section
    className={style.ComicPages}
    size="md"
    innerClassName={style.contentInner}
  >
    <h4>Media</h4>
    <div className={style.row}>
      <FluidImage imageName="cover-original.jpg" />
      <FluidImage imageName="cover-original-variant.jpg" />
    </div>
    <div className={style.row}>
      <FluidImage imageName="p11.jpg" />
      <FluidImage imageName="p37.jpg" />
      <FluidImage imageName="p43.jpg" />
    </div>
    <div className={style.row}>
      <FluidImage imageName="p25-26.jpg" />
      <FluidImage imageName="p01-02.jpg" />
    </div>
  </Section>
);

export default ComicPagesSection;
