import React from 'react';
import Sound from 'react-sound';
// import FluidImage from '../../fluid-image';
// import style from './song.module.scss';

if (
  typeof window !== 'undefined' &&
  typeof window.soundManager !== 'undefined'
) {
  window.soundManager.setup({ debugMode: false });
}

class Song extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      muteAllSounds: false,
      soundState: {
        playStatus: Sound.status.PLAYING,
        position: 0,
      },
    };
  }

  handleMuteToggle = () => {
    this.setState({ muteAllSounds: !this.state.muteAllSounds });
  };

  render() {
    return null;
    //
    // return (
    //   <>
    //     <Sound
    //       url="http://wp-vue.test.oas.nu/MASTER-short.wav"
    //       playStatus={
    //         this.state.muteAllSounds
    //           ? Sound.status.PAUSED
    //           : this.state.soundState.playStatus
    //       }
    //       playFromPosition={0}
    //       onLoading={this.handleSongLoading}
    //       onPlaying={this.handleSongPlaying}
    //       onFinishedPlaying={this.handleSongFinishedPlaying}
    //       loop={true}
    //       volume={40}
    //     />
    //
    //     <div className={style.Song}>
    //       <button onClick={this.handleMuteToggle}>
    //         {this.state.muteAllSounds ? (
    //           <FluidImage imageName="muted.png" />
    //         ) : (
    //           <FluidImage imageName="sound-on.png" />
    //         )}
    //       </button>
    //     </div>
    //   </>
    // );
  }
}

export default Song;
