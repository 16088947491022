import React from 'react';
import { Link } from 'gatsby';
import Section from '~/components/layout/section/section';
import OutLink from '~/components/common/OutLink/OutLink';
import style from './interest-section.module.scss';

const InterestSection = () => {
  return (
    <Section
      className={style.InterestSection}
      innerClassName={style.textContainerInner}
    >
      <h4>Interested in a copy?</h4>
      <p>
        <Link to="/shop" className="underline white">
          Go to the shop
        </Link>
      </p>
    </Section>
  );
};

export default InterestSection;
