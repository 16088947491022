import React from 'react';
import { useSiteMetadata } from '~/hooks/use-metadata-hooks';
import OutLink from '~/components/common/OutLink/OutLink';
import Section from '~/components/layout/section/section';
import style from './about-section.module.scss';

const countTeamMembers = (team: TeamRole[]): number => {
  return team.length;
  // return team.reduce((acc, curr) => {
  //   return acc + curr.name.length;
  // }, 0);
};

const AboutSection = () => {
  const { socialMedia, team } = useSiteMetadata();
  // const nrTeamMembers = countTeamMembers(team);

  return (
    <Section
      className={style.AboutSection}
      size="sm"
      innerClassName={style.contentInner}
    >
      <section>
        <h4>Salam Aleikum (Peace be upon you)</h4>
        <p>I am Hisham Kaine, author and creator of Infidel Comics.</p>
        <p>
          I am a huge fan of various arts of pop culture. My hobbies have
          greatly influenced me to write; movies from the 80s or any film by
          Arnold Schwarzenegger. I completed a 1-year filmmaking program at New
          York Film Academy. Later on, I realised it was too hard to make an
          action movie the way I wanted to because of how costly action scenes
          can be. By working with action in comic books, I could finally allow
          myself to be more creative no matter what spectacle one uses.
        </p>
        <p>
          Check me out on Instagram if you want to know more:
          <span>
            <OutLink
              className="underline black"
              href={socialMedia.personalInstagram}
            >
              @hisham_kaine
            </OutLink>
          </span>
        </p>
      </section>

      <section>
        <h4>Infidel Comics</h4>
        <p>
          The story of Wahid plays out in a parallel world aligned with our
          reality. What if Saddam Hussein had a weapon of mass destruction. What
          if, that weapon was a super-soldier, set loose in Iraq in the year
          2014?
          {/*Infidel Comics is located in Jönköping, Sweden, also known as{' '}
          <i>"The Jerusalem of Småland"</i>. We consist of {nrTeamMembers}{' '}
          individuals doing our best to give you a unique but familiar
          experience.*/}
        </p>
        <p>
          In the first issue, Wahid escapes an underground facility, only to
          find out that Daesh (ISIS) have invaded larger parts of Iraq. Wahid
          finds his purpose; to defend Iraq from all invaders no matter the
          costs.
        </p>
        <p>
          In issue 3 WAHID: KILL ALL DAESH, Wahid agrees to work with the CIA to
          capture the infamous "Jihadi Beatles". "The four horsemen" will join
          Wahid in the upcoming BATTLE OF TIKRIT.
        </p>
        {/*<OutLink href="https://www.kickstarter.com/projects/wahidwmd/3-wahid-kill-all-daesh?ref=website">
          Support the kickstarter for issue #3 by clicking here
        </OutLink>*/}
        {/*<p>
          We launched successful crowdfunding campaigns on
          <span>
            <OutLink
              className="underline black"
              href="https://www.kickstarter.com/projects/wahidwmd/wahid-weapon-of-mass-destruction"
            >
              Kickstarter
            </OutLink>
          </span>
          and
          <span>
            <OutLink
              className="underline black"
              href="https://www.indiegogo.com/projects/1-wahid-weapon-of-mass-destruction"
            >
              Indiegogo
            </OutLink>
          </span>
          in 2019. We are confident in the world of WAHID and that's why we are
          working hard to deliver issue #2.
        </p>
        <p>
          Follow us on Instagram for updates:
          <span>
            <OutLink className="underline black" href={socialMedia.instagram}>
              @wahid_wmd
            </OutLink>
          </span>
        </p>
        <p>We are Infidel Comics.</p>*/}
      </section>
    </Section>
  );
};

export default AboutSection;
