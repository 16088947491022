import React, { useEffect, useState } from 'react';
import Layout from '~/components/layout/layout/layout';
import SEO from '~/components/common/SEO/SEO';
import Hero from '~/components/pages/index/hero/hero';
import { getMenuHeight } from '~/components/layout/header/utils';
import { getHeroHeight } from '~/components/pages/index/hero/utils';
import AboutSection from '~/components/pages/index/about-section/about-section';
import InterestSection from '~/components/pages/index/interest-form-section/interest-section';
import ComicPagesSection from '~/components/pages/index/comic-pages-section/comic-pages-section';

const IndexPage = () => {
  const [useBlackHeader, setUseBlackHeader] = useState(false);

  useEffect(() => {
    const menuHeight = getMenuHeight();
    const heroHeight = getHeroHeight();
    const scrollLimit = heroHeight - menuHeight;

    const scrollListener = ({ target }: Event) => {
      const scrollTop =
        (target as HTMLDocument).scrollingElement?.scrollTop || 0;

      const shouldBeBlack = scrollTop >= scrollLimit;

      if (shouldBeBlack !== useBlackHeader) {
        setUseBlackHeader(() => shouldBeBlack);
      }
    };

    document.addEventListener('scroll', scrollListener);
    return () => {
      document.removeEventListener('scroll', scrollListener);
    };
  }, [useBlackHeader]);

  return (
    <Layout blackHeader={useBlackHeader} sticky={false}>
      <SEO title="Home" />
      <Hero />
      <AboutSection />
      <InterestSection />
      <ComicPagesSection />
    </Layout>
  );
};

export default IndexPage;
