import React from 'react';
// import OutLink from '~/components/common/OutLink/OutLink';
import backgroundImage from '~/static/hero4.jpg';
import kickstarterImage from '~/static/kickstarter-just-started.png';
import Song from '../song';
import style from './hero.module.scss';

const Hero = () => {
  return (
    <div className={style.Hero} id="hero">
      <Song />
      <div
        className={style.heroImageContainer}
        style={{
          backgroundImage: `url("${backgroundImage}")`,
        }}
      />
      {/*<OutLink href="https://www.kickstarter.com/projects/wahidwmd/3-wahid-kill-all-daesh?ref=website">
        <div className={style.kickstarter}>
          <img src={kickstarterImage} />
        </div>
      </OutLink>*/}
    </div>
  );
};

export default Hero;
